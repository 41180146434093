/**
 * TOP Banner SP Slider
 */

const bannerSlider = () => {
    const topSlider = document.querySelector('.js-banner-slider');

    if (topSlider) {
        //@ts-ignore
        var slider = new Splide(topSlider, {
            perPage: 1,
            pagination: false,
            arrows: false,
            rewindByDrag: false,
            drag: false,
            rewind: true,
            autoplay: true,
            type: 'loop',
        });

        slider.mount();
    }
};

export default bannerSlider;