/**
 * TOP SCROLL ANIMATIONS
 */

// eslint-disable-next-line import/no-extraneous-dependencies
import { gsap } from 'gsap';

const topAnimations = () => {

    gsap.registerPlugin(ScrollTrigger);

    gsap.registerPlugin(EaselPlugin);

    //@ts-ignore
    const fadeupAnimate = gsap.utils.toArray('.js-fadeUp');

    fadeupAnimate?.forEach((item: any) => {
        gsap.to(item, {
            scrollTrigger: {
                trigger: item,
                start: 'top+=-700',
                toggleActions: 'restart pause resume reverse',
            },
            y: 0,
            opacity: 1,
            duration: 1,
            ease: 'power3.out',
            stagger: {
                amount: 0.3
            }
        });
    });

    const popupAnimate = gsap.utils.toArray('.js-popup');

    if (popupAnimate) {
        gsap.to('.js-popup', {
            scrollTrigger: {
                trigger: '.js-popup',
                start: 'top+=-700',
            },
            scale: 1,
            duration: 1,
            delay: 0.5,
            ease: 'expo.out',
        });
    }

    const titleAnimate = gsap.utils.toArray('.js-title-anim');

    titleAnimate?.forEach((item: any) => {
        gsap.to(item, {
            scrollTrigger: {
                trigger: item,
                start: 'top+=-1200px',
            },
            y: 0,
            ease: 'power4.out',
            delay: 1,
            duration: 1,
            opacity: 1,
            // stagger: {
            //   amount: 0.3
            // }
        });
    });

    ScrollTrigger.refresh();
};

export default topAnimations;
