import test from './components/test';
import bannerSlider from './components/bannerSlider';
import topAnimations from './components/topAnimations';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    test();
    bannerSlider();
    topAnimations();
  },
  false
);
